<template>
  <v-container fluid>
    <B2bTabs/>

    <v-form ref="conf_form">
      <div class="text-blue font-semibold mt-6 mb-2">Preferences</div>
      <div class="bordered rounded-3 p-4 mb-8 bg-white">
        <div>
          <v-row class="align-center">
            <v-col lg="3" md="3" align-self="end">
              <v-checkbox
                  v-model="
                  configurations.b2b_configurations.enable_specific_products
                "
                  class="mt-2 qp-custom-checkbox"
                  false-value="0"
                  hide-details="auto"
                  label="Enable partner specific products"
                  true-value="1"
                  style="min-height: 40px !important;height: auto !important;"
              ></v-checkbox>
            </v-col>
            <v-col lg="3" md="3">
              <label for="">
                Credit day
              </label>
              <v-select
                  v-model="configurations.b2b_configurations.credit_date"
                  :items="dates"
                  :menu-props="{ bottom: true, offsetY: true }"
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
              ></v-select>
            </v-col>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="mt-4"
                >
                  mdi-information
                </v-icon>
              </template>
              <span
              >Email with all pending credits will be sent on this day to each
                partner</span
              >
            </v-tooltip>
          </v-row>
          <br>
          <p class="text-sm mb-0">Require Booking Approval:</p>
          <div class="d-flex align-center gap-x-4 gap-y-4">
            <v-checkbox
                v-model="configurations.b2b_configurations.b2b_approval"
                class="mt-2 qp-custom-checkbox"
                false-value="0"
                label="B2B"
                true-value="1"
                hide-details="auto"
            ></v-checkbox>
            <v-checkbox
                v-model="configurations.b2b_configurations.b2e_approval"
                class="mt-2 qp-custom-checkbox"
                false-value="0"
                label="B2E"
                true-value="1"
                hide-details="auto"
            ></v-checkbox>
            <v-checkbox
                v-model="configurations.b2b_configurations.b2g_approval"
                class="mt-2 qp-custom-checkbox"
                false-value="0"
                label="B2G"
                true-value="1"
                hide-details="auto"
            ></v-checkbox>
          </div>
          <!--          <v-row>-->
          <!-- <span class="sales_heading">Allow Open Dated Tickets:</span>
          <v-col lg="1" md="1">
            <v-checkbox
              v-model="configurations.b2b_configurations.b2b_open_dated"
              class="mt-2"
              false-value="0"
              true-value="1"
              label="B2B"
            ></v-checkbox>
          </v-col>
          <v-col lg="1" md="1">
            <v-checkbox
              v-model="configurations.b2b_configurations.b2e_open_dated"
              class="mt-2"
              false-value="0"
              true-value="1"
              label="B2E"
            ></v-checkbox>
          </v-col>
          <v-col lg="1" md="1">
            <v-checkbox
              v-model="configurations.b2b_configurations.b2g_open_dated"
              class="mt-2"
              false-value="0"
              true-value="1"
              label="B2G"
            ></v-checkbox>
          </v-col> -->
          <!--          </v-row>-->
        </div>
      </div>

      <div class="text-blue font-semibold mb-2">Fields Configurations</div>
      <div class="bordered rounded-3 p-4 bg-white">
        <v-row>
          <v-col>
            <table class="table text-center table-bordered">
              <thead>
              <tr class="opacity-70 tr-neon tr-rounded text-center font-bold black-text">
                <th class="text-center">Field Name</th>
                <th class="text-center">View</th>
                <th class="text-center">Mandatory</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(config, index) in configurations.field_configurations"
                  :key="index"
              >
                <td>{{ config.name }}</td>
                <td>
                  <v-btn
                      :style="
                          viewRestrictedFields.includes(config.slug) &&
                            'cursor:not-allowed;'
                        "
                      icon
                      @click="checkAsVisible(index)"
                  >
                    <TickIcon v-if="config.is_visible == 1 "/>
                    <CloseIcon v-else/>
                  </v-btn>
                </td>
                <td>
                  <v-btn
                      :style="
                          mandatoryRestrictedFields.includes(config.slug) &&
                            'cursor:not-allowed;'
                        "
                      icon
                      @click="checkAsMandatory(index)"
                  >
                    <TickIcon v-if="config.is_required == 1 "/>
                    <CloseIcon v-else/>
                  </v-btn>
                </td>
              </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
      </div>
      <div class="text-blue font-semibold mt-6 mb-2">Documents</div>
      <div
          v-for="(document, k) in configurations.field_document_configurations"
          :key="'d_' + k"
          class="bordered rounded-3 p-4 mb-8 bg-white"
      >
        <v-card-text>
          <v-row class="align-center">
            <v-col cols="12" md="3">
              <label for="">Name</label>
              <v-text-field
                  v-model="document.name"
                  background-color="#fff"
                  outlined
                  required
                  hide-details="auto"
                  dense
                  class="q-text-field shadow-0"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="4">
              <label for="">Document</label>
              <v-file-input
                  v-model="document.file"
                  background-color="#fff"
                  outlined
                  prepend-icon
                  dense
                  prepend-inner-icon="mdi-paperclip"
                  hide-details="auto"
                  class="q-text-field shadow-0"
              >
                <template v-slot:label>
                  <span v-if="!document.document_id"> Select file </span>
                  <span
                      v-if="document.document_id && !document.file"
                      class="font-weight-bold"
                  >
                    <span
                        class="text-truncate"
                        style="width: 70%; display: inline-block"
                    >{{ document.uploaded_original_file_name }}</span
                    >
                    <span
                        class="text-truncate"
                        style="width: 20%; display: inline-block"
                    >.{{
                        document.uploaded_original_file_name.split(".")[
                        document.uploaded_original_file_name.split(".")
                            .length - 1
                            ]
                      }}</span
                    >
                  </span>
                </template>
              </v-file-input>
            </v-col>

            <v-col cols="12" md="2" sm="2">
              <v-checkbox
                  v-model="document.is_visible"
                  color="success"
                  label="View"
                  v-bind:false-value="0"
                  v-bind:true-value="1"
                  value="document.is_visible"
                  @change="!document.is_visible ? (document.is_required = 0) : ''"
                  hide-details="auto"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="2" sm="2">
              <v-checkbox
                  v-model="document.is_required"
                  color="success"
                  label="Mandatory"
                  v-bind:false-value="0"
                  v-bind:true-value="1"
                  value="document.is_required"
                  @change="document.is_required ? (document.is_visible = 1) : ''"
                  hide-details="auto"
              ></v-checkbox>
            </v-col>

            <v-col cols="12" md="1" sm="1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-if="checkDeletePermission($modules.b2b.partner.slug)"
                      class="mt-2"
                      color="red"
                      dark
                      fab
                      style="position: relative; top: calc(50% - 33px)"
                      v-bind="attrs"
                      x-small
                      @click="deleteDocuments(k)"
                      v-on="on"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                Delete
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
      <div class="add_btn">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-if="checkWritePermission($modules.b2b.partner.slug)"
                color="teal-color"
                dark
                fab
                v-bind="attrs"
                x-small
                @click="addDocuments"
                v-on="on"
            >
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </template>
          Add Document
        </v-tooltip>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            v-if="checkWritePermission($modules.b2b.partner.slug)"
            class="ma-2 white--text teal-color"
            height="45"
            text
            @click="saveCOnfiguration()"
        >Save
        </v-btn
        >
      </v-card-actions>
    </v-form>
    <confirm-model
        v-bind="confirmModel"
        @close="confirmModel.id = null"
        @confirm="confirmActions"
    ></confirm-model>
  </v-container>
</template>

<script>
import constants from "@/utils/constants";
import B2bTabs from "@/views/B2b/B2bTabs.vue";
import CloseIcon from "@/assets/images/misc/config-table-close-icon.svg";
import TickIcon from "@/assets/images/misc/config-table-tick-icon.svg";

export default {
  components: {TickIcon, CloseIcon, B2bTabs},
  data() {
    return {
      configurations: {
        field_configurations: [],
        field_document_configurations: [],
        venue_service_configuration: {},
        deleted_field_documents: [],
        b2b_configurations: {
          b2b_approval: 0,
          b2e_approval: 0,
          b2g_approval: 0,
          // b2b_open_dated: 0,
          // b2e_open_dated: 0,
          // b2g_open_dated: 0,
          enable_specific_products: 0,
          credit_date: null,
        },
      },
      venueServiceId: null,
      viewRestrictedFields: constants.VIEWRESTRICTEDFIELDS,
      mandatoryRestrictedFields: constants.MANTATORYRESTRICTEDFIELDS,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      productType: "B2B",
      dates: [
        {name: "Don't send email", value: 0},
        {name: "1st of every month", value: 1},
        {name: "2nd of every month", value: 2},
        {name: "3rd of every month", value: 3},
        {name: "4th of every month", value: 4},
        {name: "5th of every month", value: 5},
        {name: "6th of every month", value: 6},
        {name: "7th of every month", value: 7},
        {name: "8th of every month", value: 8},
        {name: "9th of every month", value: 9},
        {name: "10th of every month", value: 10},
        {name: "11th of every month", value: 11},
        {name: "12th of every month", value: 12},
        {name: "13th of every month", value: 13},
        {name: "14th of every month", value: 14},
        {name: "15th of every month", value: 15},
        {name: "16th of every month", value: 16},
        {name: "17th of every month", value: 17},
        {name: "18th of every month", value: 18},
        {name: "19th of every month", value: 19},
        {name: "20th of every month", value: 20},
        {name: "21st of every month", value: 21},
        {name: "22nd of every month", value: 22},
        {name: "23rd of every month", value: 23},
        {name: "24th of every month", value: 24},
        {name: "25th of every month", value: 25},
        {name: "26th of every month", value: 26},
        {name: "27th of every month", value: 27},
        {name: "28th of every month", value: 28},
      ],
    };
  },
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices").then((data) => {
        if (data.length) {
          this.venueServiceId = data[0].venue_service_id;
          //   this.loadConfigurations();
        }
      });
    } else {
      this.venueServiceId = this.venueServices[0].venue_service_id;
      //   this.loadConfigurations();
    }
    if (this.$store.getters.getDocumentTypes.status == false) {
      this.$store.dispatch("loadDocumentTypes");
    }
    if (this.$store.getters.getWeekdays.status == false) {
      this.$store.dispatch("loadWeekdays");
    }
    this.getConfiguration();
  },
  computed: {
    weekdays() {
      return this.$store.getters.getWeekdays.data;
    },
    documentTypes() {
      return this.$store.getters.getDocumentTypes.data;
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
  },
  methods: {
    checkAsMandatory(index) {
      let field = this.configurations.field_configurations[index];
      if (!this.mandatoryRestrictedFields.includes(field.slug)) {
        this.configurations.field_configurations[index].is_required =
            field.is_required == 1 ? 0 : 1;
        if (field.is_required == 1 && field.is_visible == 0) {
          this.configurations.field_configurations[index].is_visible = 1;
        }
      }
    },
    checkAsVisible(index) {
      let field = this.configurations.field_configurations[index];
      if (!this.viewRestrictedFields.includes(field.slug)) {
        this.configurations.field_configurations[index].is_visible =
            field.is_visible == 1 ? 0 : 1;
        if (field.is_required == 1 && field.is_visible == 0) {
          this.configurations.field_configurations[index].is_required = 0;
        }
      }
    },
    addDocuments() {
      this.configurations.field_document_configurations.push({
        name: null,
        file: null,
        is_visible: 1,
        is_required: 1,
      });
    },
    deleteDocuments(index) {
      if (
          !this.configurations.field_document_configurations[index].name &&
          !this.configurations.field_document_configurations[index].id &&
          !this.configurations.field_document_configurations[index]
              .document_type_id
      ) {
        this.configurations.field_document_configurations.splice(index, 1);
        if (!this.configurations.field_document_configurations.length) {
          this.configurations.field_document_configurations = [{}];
        }
      } else {
        this.confirmModel = {
          id: index,
          title: "Do you want to delete this field?",
          description:
              "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "delete_document",
        };
      }
    },
    confirmActions(data) {
      if (data.type == "delete_document") {
        if (
            this.configurations.field_document_configurations[data.id].id != null
        ) {
          this.configurations.deleted_field_documents.push(
              this.configurations.field_document_configurations[data.id].id
          );
        }
        this.configurations.field_document_configurations.splice(data.id, 1);
        if (!this.configurations.field_document_configurations.length) {
          this.configurations.field_document_configurations = [{}];
        }
      }
      this.confirmModel.id = null;
    },
    getConfiguration() {
      this.showLoader();
      this.$store
          .dispatch("loadConfigurations", "B2B")
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.configurations = JSON.parse(
                  JSON.stringify(response.data.data)
              );

              if (this.configurations.field_document_configurations.length == 0) {
                this.configurations.field_document_configurations.push({
                  name: null,
                  file: null,
                  is_required: 0,
                  is_visible: 0,
                });
              } else {
                this.configurations.field_document_configurations.forEach(
                    (element) => {
                      if (!element.document_id) {
                        element.file = null;
                      }
                    }
                );
              }

              // if (this.configurations.membership_card_configuration == null) {
              //   this.configurations.membership_card_configuration = {
              //     file: null,
              //     image_path: null,
              //   };
              // } else {
              //   this.membershipCardImage =
              //     this.s3BucketURL +
              //     this.configurations.membership_card_configuration.image_path;
              // }

              if (this.configurations.b2b_configurations) {
                if (this.configurations.b2b_configurations.b2b_approval) {
                  this.configurations.b2b_configurations.b2b_approval = this.configurations.b2b_configurations.b2b_approval.toString();
                }
                if (this.configurations.b2b_configurations.b2e_approval) {
                  this.configurations.b2b_configurations.b2e_approval = this.configurations.b2b_configurations.b2e_approval.toString();
                }
                if (this.configurations.b2b_configurations.b2g_approval) {
                  this.configurations.b2b_configurations.b2g_approval = this.configurations.b2b_configurations.b2g_approval.toString();
                }
                // if (this.configurations.b2b_configurations.b2b_open_dated) {
                //   this.configurations.b2b_configurations.b2b_open_dated = this.configurations.b2b_configurations.b2b_open_dated.toString();
                // }
                // if (this.configurations.b2b_configurations.b2e_open_dated) {
                //   this.configurations.b2b_configurations.b2e_open_dated = this.configurations.b2b_configurations.b2e_open_dated.toString();
                // }
                // if (this.configurations.b2b_configurations.b2g_open_dated) {
                //   this.configurations.b2b_configurations.b2g_open_dated = this.configurations.b2b_configurations.b2g_open_dated.toString();
                // }
                if (
                    this.configurations.b2b_configurations.enable_specific_products
                ) {
                  this.configurations.b2b_configurations.enable_specific_products = this.configurations.b2b_configurations.enable_specific_products.toString();
                }
              } else {
                this.configurations.b2b_configurations = {
                  b2b_approval: 0,
                  b2e_approval: 0,
                  enable_specific_products: 0,
                  b2g_approval: 0,
                  // b2b_open_dated: 0,
                  // b2e_open_dated: 0,
                  // b2g_open_dated: 0,
                  credit_date: null,
                };
              }

              this.configurations.deleted_field_documents = [];

              this.$forceUpdate();
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
            this.hideLoader();
          });
    },

    saveCOnfiguration() {
      if (!this.$refs.conf_form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      let formData = new FormData();
      formData.append(`product_type`, "B2B");
      this.configurations.field_configurations.forEach((item, index) => {
        for (let key in item) {
          if (item.product_type_id == 6) {
            delete item.product_type_id;
            delete item.id;
          }
          if (item[key] != null) {
            formData.append(
                `field_configurations[${index}][${key}]`,
                item[key]
            );
          }
        }
      });
      if (this.configurations.deleted_field_documents.length) {
        this.configurations.deleted_field_documents.forEach((item, index) => {
          formData.append(`deleted_field_documents[${index}]`, item);
        });
      }

      if (this.configurations.field_document_configurations.length) {
        this.configurations.field_document_configurations.forEach(
            (item, index) => {
              if (item.name) {
                for (let key in item) {
                  formData.append(
                      `field_document_configurations[${index}][${key}]`,
                      item[key]
                  );
                }
              }
            }
        );
      }

      for (let key in this.configurations.venue_service_configuration) {
        if (
            this.configurations.venue_service_configuration[key] != null &&
            this.configurations.venue_service_configuration[key] != ""
        ) {
          formData.append(
              `venue_service_configuration[${key}]`,
              this.configurations.venue_service_configuration[key]
          );
        }
      }

      formData.append(
          `enable_specific_products`,
          this.configurations.b2b_configurations.enable_specific_products
      );

      formData.append(
          `b2g_approval`,
          this.configurations.b2b_configurations.b2g_approval
      );
      formData.append(
          `b2b_approval`,
          this.configurations.b2b_configurations.b2b_approval
      );
      formData.append(
          `b2e_approval`,
          this.configurations.b2b_configurations.b2e_approval
      );
      // formData.append(
      //   `b2b_open_dated`,
      //   this.configurations.b2b_configurations.b2b_open_dated
      // );
      // formData.append(
      //   `b2e_open_dated`,
      //   this.configurations.b2b_configurations.b2e_open_dated
      // );
      // formData.append(
      //   `b2g_open_dated`,
      //   this.configurations.b2b_configurations.b2g_open_dated
      // );
      formData.append(
          `credit_date`,
          this.configurations.b2b_configurations.credit_date
      );

      this.showLoader();
      this.$http({
        method: "post",
        data: formData,
        url: "venues/configurations",
        headers: {
          "Content-Type": "multipart/form-data; boundary=${form._boundary}",
        },
      })
          .then((response) => {
            if (response.status == 200) {
              this.gotoB2b();
              this.$store.dispatch("loadConfigurations", "B2B").then(() => {
                this.$nextTick(() => {
                  this.$forceUpdate;
                });
              });
              this.hideLoader();
              this.showSuccess("Configuration successfully updated");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
            this.hideLoader();
            this.$store.dispatch("loadConfigurations", "B2B");
            this.$store.dispatch("loadVenueServices");
          });
    },
    gotoB2b() {
      this.$router.push({
        name: "B2bPartners",
      });
    },

    onFileChange(e) {
      if (e) this.membershipCardImage = window.URL.createObjectURL(e);
    },
    setEmailContent(content) {
      this.email_message = content;
    },
    copyPlaceholder(data) {
      const el = document.createElement("textarea");
      el.value = data;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
          document.getSelection().rangeCount > 0
              ? document.getSelection().getRangeAt(0)
              : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.showInfo("Placeholder for " + data + " copied to clipboard", 2000);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
  },
};
</script>

<style scoped>
.settings_table {
  width: 100%;
  text-align: center !important;
}

.settings_table th {
  text-align: center !important;
}

.settings_table td,
.settings_table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.settings_table tr {
  background-color: #f2f2f2;
}

.settings_table tbody td:first-child {
  font-weight: bolder;
  font-size: 13px;
}

.settings_table tr:hover {
  background-color: #ddd;
}

.settings_table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #066a8c;
  color: white;
}

.member-card {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  /* background-size: contain; */
  /* background-image: url("../../../assets/images/default_images/membership_card.png"); */
}

.member-card span {
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color: white;
}

span.sales_heading {
  margin-left: 1%;
  margin-top: 1.5%;
  line-height: 20px;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
}
</style>
